<template>
  <DataTable v-if="created" :options="options" />
</template>

<script>
import { FeatureMixin } from '@tutti/mixins';
import { VENUE_STATUSES, VENUE_VISIBILITIES } from '@tutti/constants';

export default {
  mixins: [FeatureMixin],
  data() {
    return {
      created: false,
      options: {
        export: false,
        sync: true,
        action: {
          child: 'space',
          showDuplicate: true,
          showChildList: true,
          showHistory: true,
          showPreview: true,
          downloadMedia: true,
          showDeleteVenue: true,
        },
        actions: [
          {
            text: 'Open',
            body: { status: 'open' },
            message: 'open',
          },
          {
            text: 'Close',
            body: { status: 'closed' },
            message: 'close',
          },
          {
            text: 'Verify',
            body: { isVerified: true },
          },
          {
            text: 'Unverify',
            body: { isVerified: false },
          },
          {
            text: 'Add in Sitemap',
            body: { sitemap: true },
          },
          {
            text: 'Remove from Sitemap',
            body: { sitemap: false },
          },
        ],
        filters: [],
        headers: [
          {
            text: 'Name',
            value: 'name',
            filter: true,
            capitalize: true,
            component: { name: 'dtView', props: { name: 'location', permission: 'location' } },
          },
          { text: 'Postcode', value: 'address.postcode', filter: true },
          { text: 'What3Words', value: 'what3Words.words', filter: true, component: 'dtWhat3Words' },
          {
            text: 'Host profile',
            value: 'account',
            sortable: false,
            filter: true,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Status',
            value: 'status',
            width: '200px',
            component: { name: 'dtVenueStatus' },
            filter: {
              type: 'autocomplete',
              items: VENUE_STATUSES,
            },
          },
          {
            text: 'Visibility',
            value: 'visibility',
            width: '150px',
            capitalize: true,
            component: { name: 'dtChip' },
            filter: {
              type: 'autocomplete',
              items: VENUE_VISIBILITIES,
            },
          },
          {
            text: 'Verified',
            value: 'isVerified',
            width: '150px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Sitemap',
            value: 'sitemap',
            width: '120px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
        ],
      },
    };
  },
  created() {
    this.$setFeatures({
      disciplines: true,
      activities: true,
      locationTypes: true,
    });

    this.options.filters = [
      {
        label: 'Disciplines',
        items: this.disciplines,
        itemText: 'name',
        itemValue: '_id',
        key: 'disciplines.attributeId',
      },
      {
        label: 'Activities',
        items: this.activities,
        itemText: 'name',
        itemValue: '_id',
        key: 'activities.attributeId',
      },
      {
        label: 'Location types',
        items: this.locationTypes,
        itemText: 'name',
        itemValue: '_id',
        key: 'types.attributeId',
      },
      {
        label: 'TBD',
        items: [],
        itemText: 'text',
        itemValue: 'value',
        key: 'tbd',
        disabled: true,
      },
    ];

    this.created = true;
  },
};
</script>
